import { motion, AnimatePresence } from "framer-motion";

interface IProps {
  message?: string;
  display?: boolean;
}

export function ValidationError({
  message,
  display = true,
}: IProps): JSX.Element {
  return (
    <AnimatePresence>
      {display && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          className="mt-1 overflow-hidden text-sm leading-sm text-red"
        >
          {message}&nbsp;
        </motion.div>
      )}
    </AnimatePresence>
  );
}
