"use client";

import { create } from "zustand";
import { PlainMessage } from "@bufbuild/protobuf";
import { ShopConfiguration } from "@egocentric-systems/ts-apis/booking_gateway/v1/shop_config_pb";

type UseShopConfig = {
  shopConfig: PlainMessage<ShopConfiguration>;
  setConfig: (config: PlainMessage<ShopConfiguration>) => void;
};

export const useShopConfig = create<UseShopConfig>((set) => {
  const setConfig = (shopConfig: PlainMessage<ShopConfiguration>): void => {
    set({ shopConfig: shopConfig });
  };

  return { setConfig, shopConfig: {} as PlainMessage<ShopConfiguration> };
});
