import { PlainMessage } from "@bufbuild/protobuf";
import { FanTicket } from "@egocentric-systems/ts-apis/shop_config/types/v1/fanticket_pb";
import { useCart } from "~/hooks/useCart";
import { useMemo } from "react";
import { useShopConfig } from "~/hooks/useShopConfig";

export function useAddOnAvailable(): boolean {
  const { items } = useCart();
  const {
    shopConfig: { fanTicket },
  } = useShopConfig();

  return useMemo(() => {
    return (
      Number(items?.length) > 0 &&
      items.some((item) =>
        item.eventOrBundle.case === "event"
          ? fanTicket?.eventIds?.includes(item.eventOrBundle.value.id)
          : item.eventOrBundle.value?.eventIds.every((eventId) =>
              fanTicket?.eventIds?.includes(eventId),
            ),
      )
    );
  }, [items, fanTicket]);
}
