import * as React from "react";
import { FormEvent, InputHTMLAttributes, ReactNode } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { cn } from "~/lib/utils";
import { ValidationError } from "../ValidationError";

interface InputProps<C extends FieldValues = FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  name?: Path<C>;
  control?: Control<C>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "number" | "email" | "search" | "password";
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  pattern?: string;
  className?: string;
  wrapperClassname?: string;
  hideError?: boolean;
}

function numbersOnly(value: string): number | string {
  if (value === "") {
    return value;
  }

  if (/^-?\d*\.?\d*$/.test(value)) {
    return value.endsWith(".") ? value : Number(value);
  } else {
    return (
      Number(
        `${value}`
          .replaceAll(/[^-?\d*.$]/g, "")
          .split(".")
          .slice(0, 2)
          .join("."),
      ) || ""
    );
  }
}

export function Input<C extends FieldValues = FieldValues>({
  name,
  control,
  type = "text",
  required,
  className,
  onChange,
  hideError,
  wrapperClassname,
  ...props
}: InputProps<C>): ReactNode {
  if (name && control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          const { error } = fieldState;

          function onChangeNumber({
            currentTarget: { value },
          }: FormEvent<HTMLInputElement>): void {
            field.onChange(numbersOnly(value));
          }

          const onFieldChange =
            onChange ?? (type === "number" ? onChangeNumber : field.onChange);

          return (
            <div className={cn("input-wrapper", wrapperClassname)}>
              <input
                type={type === "number" ? "text" : type}
                required={required}
                id={name}
                {...field}
                {...props}
                onChange={onFieldChange}
                className={cn(
                  "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                  className,
                )}
              />
              {!hideError && (
                <ValidationError
                  display={Boolean(fieldState.error)}
                  message={error?.message}
                />
              )}
            </div>
          );
        }}
      />
    );
  }

  return (
    <div className="input-wrapper">
      <input
        type={type === "number" ? "text" : type}
        required={required}
        id={name}
        {...props}
        onChange={onChange}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
      />
    </div>
  );
}
