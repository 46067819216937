"use client";

import { proto3 } from "@bufbuild/protobuf";
import { ShopLanguage } from "@egocentric-systems/ts-apis/shop_config/types/v1/common_pb";
import { parse } from "accept-language-parser";
import { useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { EGOSYS_LNG_COOKIE } from "~/lib/definitions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/it";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/hu";
import "dayjs/locale/hr";
import "dayjs/locale/cs";
import "dayjs/locale/pl";
import "dayjs/locale/pt";

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isBetween);

export function useDayjsLocale(): null {
  const locale = useLocale();

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  return null;
}

export function useLocale(): string {
  const [cookies] = useCookies([EGOSYS_LNG_COOKIE]);

  const locale = useMemo(() => {
    if (typeof navigator === "undefined") return "en";
    const browserLng = parse(navigator.language)[0]?.code;
    const cookieLocale = cookies[EGOSYS_LNG_COOKIE];

    const supportedLocales = proto3
      .getEnumType(ShopLanguage)
      .values.map((value) => value.localName.toLowerCase());

    const check = (lng: string) => {
      if (supportedLocales.includes(lng)) return lng;
      return "en";
    };

    if (cookieLocale) return check(cookieLocale);
    if (browserLng) return check(browserLng);
    return "en";
  }, [cookies]);

  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  return locale;
}
