"use client";

import { useEffect, useState } from "react";
import { Translations } from "~/lib/definitions";
import en from "~/lib/dictionaries/en.json";
import { useLocale } from "./useLocale";

export function useTranslations(): Translations {
  const [t, setT] = useState<Translations>(en);
  const locale = useLocale();

  useEffect(() => {
    import(`~/lib/dictionaries/${locale}.json`)
      .then((mod) => setT(mod.default))
      .catch(() => setT(en));
  }, [locale]);

  return t;
}
